import { Auth } from '@aws-amplify/auth';

const userPoolId = import.meta.env.VITE_USERPOOLID;
const userPoolWebClientId = import.meta.env.VITE_USERPOOL_WEBCLIENT_ID;
const region = import.meta.env.VITE_AWS_REGION;
export const AWSConfig = {
  region,
  userPoolId,
  userPoolWebClientId,
  cookieStorage: {
    domain: import.meta.env.VITE_DOMAIN,
    secure: import.meta.env.NODE_ENV !== 'development',
    samesite: 'lax',
  },
};

export const s3BasePath = import.meta.env.VITE_S3_BASEPATH;
export const S3FlagUrl = `${s3BasePath}${import.meta.env.VITE_S3_FLAG_URL}`;
export const s3ThumbnailsUrl = `${s3BasePath}${import.meta.env.VITE_S3_THUMBNAILS_URL}`;
export const s3OriginalUrl = `${s3BasePath}${import.meta.env.VITE_S3_ORIGINAL_IMAGES_URL}`;
export const s3ThumbnailsUrlUseCase = `${s3BasePath}${import.meta.env.VITE_S3_USECASE_THUMBNAILS_URL}`;
export const s3OriginalUrlUseCase = `${s3BasePath}${import.meta.env.VITE_S3_USECASE_ORIGINAL_URL}`;

// eslint-disable-next-line max-len
export const s3UseCaseHowToOriginalUrl = `${s3BasePath}${import.meta.env.VITE_S3_USECASE_HOW_TO_THUMBNAILS_ORIGINAL_URL}`;

export const s3UseCaseHowToThumbnailsUrl = `${s3BasePath}${import.meta.env.VITE_S3_USECASE_HOW_TO_THUMBNAILS_URL}`;

export const s3UseCaseConfigUrl = `${s3BasePath}${import.meta.env.VITE_S3_USECASE_AIMODELS_URL}`;

export const s3CustimItemImageOriginalUrl = `${s3BasePath}${import.meta.env.VITE_S3_CUSTOM_ITEM_IMAGE_ORIGINAL_URL}`;

export const s3CustimItemImageThumbnailUrl = `${s3BasePath}${import.meta.env.VITE_S3_CUSTOM_ITEM_IMAGE_THUMBNAIL_URL}`;

export const configAmplify = function configAmplify() {
  Auth.configure(AWSConfig);
};

export const loakliseUrl = import.meta.env.VITE_LOKALISE_PROJECT;
export const lokaliseProjectId = import.meta.env.VITE_LOKALISE_PROJECTID;
